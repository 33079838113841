import React from "react";
import CountUp from "react-countup";

import img from "../../media/images/Men-Waxing-Private-Areas-1.png";
import icon from "../../media/images/Full-Body-Wax-Price.png";
import img1 from "../../media/images/Mens-Waxing.png";
import img2 from "../../media/images/Mens-Waxing-Delhi.png";
import img3 from "../../media/images/Male-Full-Body-Waxing-Cost-In-Delhi.png";
import img4 from "../../media/images/Full-Body-Waxing.png";
import img5 from "../../media/images/Men-Body-Waxing-Near-Me.png";
import img6 from "../../media/images/Private-Part-Hair-Removal-Salon-Near-Me.png";

const WhyGlazma = () => {
  return (
    <div>
      <div className="why-us">
        <div>
          <h2 className="why-us-heading">WHY CHOOSE GLAZMA - MEN'S SALON</h2>
          <div className="why-us-icon">
            <img title="Body Waxing Near Me" src={icon} alt="img" />
          </div>
          <div className="why-us-text">
            We are manscaping & waxing professional and experienced, well
            qualified, and trained for Day-to-Day care. We offer special care
            for corporate and special occasions like wedding grooming, honeymoon
            grooming, party, or meeting special grooming at your preferred
            location.
          </div>

          <div className="why-us-content">
            <div className="why-us-content-col">
              <div className="why-us-content-col-list">
                <div className="why-us-content-col-list-icon">
                  <img title="Hair stylist at home" src={img1} alt="img" />
                </div>
                <div className="why-us-content-col-list-content">
                  <div className="why-us-content-col-list-content-heading">
                    Branded Cosmetics
                  </div>
                  <div className="why-us-content-col-list-content-text">
                    We always use branded products and cosmetics for grooming
                    and personal care services and always sanitise products
                    before use.
                  </div>
                </div>
              </div>

              <div className="why-us-content-col-list">
                <div className="why-us-content-col-list-icon">
                  <img title="Beauty parlour services at doorstep" src={img2} alt="img" />
                </div>
                <div className="why-us-content-col-list-content">
                  <div className="why-us-content-col-list-content-heading">
                    Hygiene Friendly
                  </div>
                  <div className="why-us-content-col-list-content-text">
                    Our quality team always assure to use hygiene friendly
                    equipments and desposable items like razor, wipes towels
                    etc.
                  </div>
                </div>
              </div>

              <div className="why-us-content-col-list">
                <div className="why-us-content-col-list-icon">
                  <img title="Super salon at home" src={img3} alt="img" />
                </div>
                <div className="why-us-content-col-list-content">
                  <div className="why-us-content-col-list-content-heading">
                    Trained Professionals
                  </div>
                  <div className="why-us-content-col-list-content-text">
                    Our all grooming experts are well qualified and certified in
                    their services they all have a rich experience of 5+ years.
                  </div>
                </div>
              </div>
            </div>

            <div className="why-us-content-col">
              <img title="Body polish near me" src={img} alt="img" />
            </div>

            <div className="why-us-content-col">
              <div className="why-us-content-col-list">
                <div className="why-us-content-col-list-icon">
                  <img title="Body polish at home" src={img4} alt="img" />
                </div>
                <div className="why-us-content-col-list-content">
                  <div className="why-us-content-col-list-content-heading">
                    Services at Home
                  </div>
                  <div className="why-us-content-col-list-content-text">
                    No waiting, Glazma comes to your doorstep at scheduled time
                    so you can engaged in pre planned activities without stress.
                  </div>
                </div>
              </div>

              <div className="why-us-content-col-list">
                <div className="why-us-content-col-list-icon">
                  <img title="Body polish expert at home" src={img5} alt="img" />
                </div>
                <div className="why-us-content-col-list-content">
                  <div className="why-us-content-col-list-content-heading">
                    Head to Toes Service
                  </div>
                  <div className="why-us-content-col-list-content-text">
                    We provide one stop solution to men's shave, trim and hair
                    designs to trasform a normal men to a gentlemen .
                  </div>
                </div>
              </div>

              <div className="why-us-content-col-list">
                <div className="why-us-content-col-list-icon">
                  <img title="Hair color at home" src={img6} alt="img" />
                </div>
                <div className="why-us-content-col-list-content">
                  <div className="why-us-content-col-list-content-heading">
                    Time saving
                  </div>
                  <div className="why-us-content-col-list-content-text">
                    No waiting, Glazma comes to your doorstep at scheduled time
                    so you can engaged in pre planned activities without stress.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="why-us-increment">
        <div>
          <div className="why-us-increment-col">
            <div className="why-us-increment-col-heading">
              <CountUp end={85} duration="5" enableScrollSpy /> %
            </div>
            <div className="why-us-increment-col-text">
              OF WOMEN THINK BAD GROOMING IS A MAJOR TURN OFF IN MENS
              PERSONALITY.
            </div>
          </div>

          <div className="why-us-increment-col">
            <div className="why-us-increment-col-heading">
              <CountUp end={80} duration="5" enableScrollSpy /> %
            </div>
            <div className="why-us-increment-col-text">
              OF WOMEN THINK, MEN SHOULD MANSCAPE UNDERARMS AND BELOW THE BELT
            </div>
          </div>

          <div className="why-us-increment-col">
            <div className="why-us-increment-col-heading">
              <CountUp end={89} duration="5" enableScrollSpy /> %
            </div>
            <div className="why-us-increment-col-text">
              OF MEN THINK GROOMING IS ESSENTIAL TO THEIR PROFESSIONAL SUCCESS
            </div>
          </div>

        
          
        </div>
          
          <a href="#book-now" className="landing-page-button-web-yellow">
                  Book Call Appointment
                  </a>

                  
                  <a href="#book-appointment" className="landing-page-button-mobile-yellow">
                  Book Call Appointment
                  </a>
                  
      </div>
    </div>
  );
};

export default WhyGlazma;
