import React, { useEffect, useState } from "react";
import img from "../../media/images/Full-Body-Wax-Price.png";
import { useDispatch } from "react-redux";
import { addToCart } from "../../store/cartSlice";


import icon1 from "../../media/images/Full-Body-Wax-Price-In-Delhi.png"
import icn1 from "../../media/images/hair trim icon.png"
import icn2 from "../../media/images/facial icon.png"
import icn3 from "../../media/images/waxing icon.png"
import icn4 from "../../media/images/men grooming icon.png"
import icn5 from "../../media/images/manicare icon.png"
import icn10 from "../../media/images/cleanup,facial,scrub icon.png"

import icn6 from "../../media/images/mens-full-body-grooming.png"
import icn7 from "../../media/images/mens-oxy-glow-package-icon.png"

const HomePackageComponent = ({h1}) => {

  const [packageList, setPackageList] = useState([])

  const loadData = async () => {

    // let res = await fetch("http://localhost:8000/all-packages", {
    let res = await fetch("https://api.shop.glazma.com/all-packages", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    res = await res.json();
    setPackageList(res.data);

  };

  useEffect(() => {
    loadData();
  }, []);


  // -----------------------------------
  const dispatch = useDispatch();

  
  
  return (
    <div>

<div className="hidden-icon">
        <img title="Mens waxing cost" src={icon1} alt="icon" />
        <img title="Mens waxing price" src={icn1} alt="icon" />
        <img title="Mens waxing in Delhi" src={icn2} alt="icon" />
        <img title="Mens waxing in Noida" src={icn3} alt="icon" />
        <img title="Mens waxing in Ghaziabad" src={icn4} alt="icon" />
        <img title="Mens waxing in Greater noida" src={icn5} alt="icon" />
        <img title="Mens waxing in Gurgaon" src={icn10} alt="icon" />
        <img title="Mens waxing in Noida Extension" src={icn6} alt="icon" />
        <img title="Mens body wax near me" src={icn7} alt="icon" />
      </div>

      <div className="home-package">
        <div>
          {
            h1?
          <h1 className="home-package-heading-1">
            MANSCAPING & BODY WAXING SERVICES PACKAGE
          </h1>
            :
          <h2 className="home-package-heading-1">
            GLAZMA MEN'S GROOMING SERVICES PACKAGES
            {/* MANSCAPING & BODY WAXING SERVICES PACKAGE */}
          </h2>
          }
          <div className="home-package-head-icon">
            <img title="Mens body wax near me" src={img} alt="icon" />
          </div>
          <div className="home-package-head-text">
            {/* <Link>Glazma offers a range of men's beauty services</Link>,
            including body waxing, facial, manicure, and pedicure and grooming
            treatments. Our experienced team uses high-quality products and
            techniques to ensure that every client looks and feels their best. */}

We offers a range of beauty services tailored specifically for men. Our experienced team provides personalized treatments to enhance your natural features.
            
          </div>
          <div className="home-package-content">

          {
  packageList.map(e=>(
    
            <div className="home-package-list">
            <div className="home-package-">
              <div className="home-package-icon"><img title=" Best body wax artist near me" src={e.iconName} alt="icon" /></div>
              <div className="home-package-heading">{e.fullName} </div>
              <div className="home-package-text">{e.packagePoints}</div>
              <div className="home-package-price"><s>Rs. {e.mainPrice} </s> {e.price}</div>
            </div>
            <div>
              <div className="home-package-btn"><span onClick={() => dispatch(addToCart(e))}>add</span></div>

            </div>

            </div>

))
}
            
            
            </div>

            
            <a href="#book-now" className="landing-page-button-web-yellow landing-page-button-black">
                  Book Call Appointment
                  </a>

                  <a href="#book-appointment" className="landing-page-button-mobile-yellow landing-page-button-black">
                  Book Call Appointment
                  </a>
              

        </div>
      </div>
    </div>
  );
};

export default HomePackageComponent;
