import React from "react";
import img from "../../media/images/Full-Body-Hair-Removal-For-Male-Salon.png"
import img1 from "../../media/images/delhi.png"
import img2 from "../../media/images/noida.png"
import img3 from "../../media/images/greater-noida.png"
import img4 from "../../media/images/gurgaon.png"
// import img4 from "../../media/images/gurgaon (1).png"
import img5 from "../../media/images/ghaziabad.png"
import img6 from "../../media/images/faridabad.png"
import img7 from "../../media/images/bangalore.png"
import img8 from "../../media/images/chandigarh.png"
import { Link } from "react-router-dom";

const LandingLocationComponent = () => {
  return (
    <div>
      <div className="location">
        <div>
          <h2 className="location-heading">GLAZMA MEN'S SALON AT HOME SERVICED AREA</h2>
          <div className="location-icon"><img title="location icon" src={img} alt="icon" /></div>
          <div className="location-col">

            <div title="Best Hair Removal For Bikini Area" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img1} alt="location-icon" /></div>
              <div className="location-list-text">Delhi</div>
            </div>
            

            <div title="Cheap Waxing Near Me" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img2} alt="location-icon" /></div>
              <div className="location-list-text">Noida</div>
            </div>
            

            <div title="Best Way To Get Rid Of Pubic Hair" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img3} alt="location-icon" /></div>
              <div className="location-list-text">Greater Noida</div>
            </div>
            

            <div title="Brazilian Sugaring Near Me" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img4} alt="location-icon" /></div>
              <div className="location-list-text">Gurgaon</div>
            </div>
            

            <div title="Underarm Waxing Near Me" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img5} alt="location-icon" /></div>
              <div className="location-list-text">Ghaziabad</div>
            </div>
            

            <div title="Bikini Area Hair Removal" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img6} alt="location-icon" /></div>
              <div className="location-list-text">Faridabad</div>
            </div>
            

            
            
            
          </div>

          <a href="#book-now" className="landing-page-button-web-yellow landing-page-button-black">
                  Book an Appointment
                  </a>

                  
                  <a href="#book-appointment" className="landing-page-button-mobile-yellow landing-page-button-black">
                  Book an Appointment
                  </a>
          
        </div>
      </div>
    </div>
  );
};

export default LandingLocationComponent;
