import React from "react";
import LandingNavbar from "../../components/user/LandingNavbar";
import LandingHeader from "../../components/user/LandingHeader";
import NumberIncrementComponent from "../../components/user/NumberIncrementComponent";
import HomePackageComponent from "../../components/user/HomePackageComponent";
import WhyGlazma from "../../components/user/WhyGlazma";
import NavTop2Component from "../../components/user/NavTop2Component";
import LandingWhyManscapeComponent from "../../components/user/LandingWhyManscapeComponent";
import LandingTestimonialComponent from "../../components/user/LandingTestimonialComponent";
import LandingLocationComponent from "../../components/user/LandingLocationComponent";
import LandingFooterComponent from "../../components/user/LandingFooterComponent";
import FaqComponent from "../../components/user/FaqComponent";
import LandingPricePageComponent from "../../components/user/LandingPricePageComponent";
import ContactButtons from "../../components/user/ContactButtons";

const LandingPage = ({location}) => {
  return (
    <div>
      <div className="landing-page">
      <NavTop2Component/>

      <div id="book-now" className="landing-web-target"></div>
      <div className="landing-page-navbar">
        <LandingNavbar />

      </div >
        <LandingHeader location={location} />

        <NumberIncrementComponent />
        <LandingWhyManscapeComponent />
        
        <div id="services">
          <LandingPricePageComponent />
        </div>
        
        <div id="packages">
        <HomePackageComponent />
        </div>
        
        <div id="why-us">
        <WhyGlazma />    
        </div>
        
        <div id="testimonial">
        <LandingTestimonialComponent />    
        </div>
        
        <div id="faqs">
      <FaqComponent /> 
        </div>

        <LandingLocationComponent />    

        <LandingFooterComponent />    

      </div>
              <ContactButtons/>
      
    </div>
  );
};

export default LandingPage;
