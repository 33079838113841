import React, { useRef } from "react";
import logo from "../../media/images/glazma-logo.png";
import { Link } from "react-router-dom";

const LandingNavbar = () => {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div>
    <div className="navbar-main">
    <div className="navbar">
      <div className="nav-logo">
        <div >
          <img src={logo} alt="logo" />
        </div>
      </div>

      <div className="nav-menu" ref={navRef}>
        <a onClick={showNavbar} href="#services">SERVICES</a>
        <a onClick={showNavbar} href="#packages">PACKAGES</a>
        <a href="#why-us">WHY US</a>
        <a href="#testimonial">TESTIMONIALS</a>
        <a href="#faqs">FAQ</a>
        {/* <Link className="landing-nav-button" to="tel:+919658153153"><i class="fa-solid fa-phone-volume"></i> &nbsp; BOOK APPOINTMENT</Link> */}


        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <i className="fa-solid fa-circle-xmark"></i>
        </button>
      </div>
      
      <button className="nav-btn menu-btn" onClick={showNavbar}>
        <i className="fa-solid fa-bars"></i>
      </button>
    </div>
    </div>
  </div>
  )
}

export default LandingNavbar