import React from 'react'
import Navbar from '../../components/user/Navbar'
import FooterComponent from '../../components/user/FooterComponent'
import { Link } from 'react-router-dom'
import ContactButtons from '../../components/user/ContactButtons'

const ConfirmBookingPage = () => {
  return (
    <div>
        <Navbar/>

        <div className="error-page">
            <div>
                {/* <div className="error-page-heading-1">Thanks For Booking</div> */}
                <div className="error-page-heading-1">Thank You</div>
                <div className="error-page-heading-2">Our team will contact you shortly</div>
                {/* <div className="error-page-heading-3">Contact: <Link target='blank' to="tel:+919658153153"> +91 9658-153-153</Link></div> */}
                <br />
                <div className="error-page-button"><Link to="/">Back to home page</Link></div>
            </div>
        </div>

        <FooterComponent/>
        {/* <ContactButtons/> */}
    </div>
  )
}

export default ConfirmBookingPage