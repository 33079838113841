import React from 'react'
import img from "../../media/images/Full-Body-Wax-Price.png";

import why1 from "../../media/images/Waxing-For-Men.png"
import why2 from "../../media/images/Brazilian-Wax-Near-Me.png"
import why3 from "../../media/images/Bikini-Wax-Near-Me.png"
import why4 from "../../media/images/Full-Body-Waxing-In-Delhi-For-Male.png"


const LandingWhyManscapeComponent = () => {
  return (
    <div>

         <div className="why-manscape">
                <div>
                  <h3 className="why-manscape-heading-1">
                    TAKE CARE OF YOUR BODY SKIN NEAR ME
                  </h3>
                  <h2 className="why-manscape-heading-2">WHY SHOULD YOU MANSCAPE?</h2>
                  <div className="why-manscape-heading-3">
                    <img title="Mens parlour in delhi" src={img} alt="img" />
                  </div>
        
                  <div className="why-manscape-points">
                    
                    <div className="why-manscape-points-list">
        
                      <div className="why-manscape-points-list-head">
                      <div className="why-manscape-points-list-icon"><img title="Mens beauty parlour in noida" src={why1} alt="img" /></div>
                      <div className="why-manscape-points-list-heading">HYGIENE</div>
                      </div>
                      <div className="why-manscape-points-list-text">Excess hair can trap dirt and sweat, resulting in odor and bacteria in bodyparts.</div>
                    </div>
        
                    <div className="why-manscape-points-list">
                      <div className="why-manscape-points-list-head">
                      <div className="why-manscape-points-list-icon"><img title="Groom Makeup" src={why2} alt="img" /></div>
                      <div className="why-manscape-points-list-heading">APPEARANCE</div>
                    </div>
                      <div className="why-manscape-points-list-text">Trimming the hedges makes the tree look bigger. Same with our body parts.</div>
                    </div>
        
                    <div className="why-manscape-points-list">
                      <div className="why-manscape-points-list-head">
                      <div className="why-manscape-points-list-icon"><img title="corporate barber" src={why3} alt="img" /></div>
                      <div className="why-manscape-points-list-heading">ATTRACTIVENESS</div>
                    </div>
                      <div className="why-manscape-points-list-text">8 out of every 10 partners find manscaped men more attractive than unkempt men.</div>
                    </div>
        
                    <div className="why-manscape-points-list">
                      <div className="why-manscape-points-list-head">
                      <div className="why-manscape-points-list-icon"><img title=" Mens beauty services at home" src={why4} alt="img" /></div>
                      <div className="why-manscape-points-list-heading">CONFIDENCE</div>
                    </div>
                      <div className="why-manscape-points-list-text">Manscaping imparts confidence, giving you an edge in your career and social life.</div>
                    </div>
        
                  </div>

                  <a href="#book-now" className="landing-page-button-web-yellow">
                  Book an Appointment
                  </a>

                  <a href="#book-appointment" className="landing-page-button-mobile-yellow">
                  Book an Appointment
                  </a>
        
        
                </div>
              </div>
        
    </div>
  )
}

export default LandingWhyManscapeComponent