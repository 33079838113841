import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import NumberIncrementComponent from './NumberIncrementComponent'
import WhyGlazma from './WhyGlazma'
import LocationComponent from './LocationComponent'
import FooterComponent from './FooterComponent'

import { TypeAnimation } from "react-type-animation";


// package --------------------------------------------------------
import img from "../../media/images/Full-Body-Wax-Price.png";

import icon1 from "../../media/images/Full-Body-Wax-Price-In-Delhi.png"
import icn1 from "../../media/images/hair trim icon.png"
import icn2 from "../../media/images/facial icon.png"
import icn3 from "../../media/images/waxing icon.png"
import icn4 from "../../media/images/men grooming icon.png"
import icn5 from "../../media/images/manicare icon.png"
import icn10 from "../../media/images/cleanup,facial,scrub icon.png"



import { Helmet } from "react-helmet";
import HomePackageComponent from './HomePackageComponent'
import PricePageComponent from './PricePageComponent'



const LocationPageComponent = ({location}) => {

  

    // let location = window.location.pathname.replace(/\//g,'');

    // if(location === "greater-noida"){
    //     location = "greater noida"
    // }
    
    // if(location === "panaji-goa"){
    //     location = "panaji, goa"
    // }
    // if(location === "baldev-nagar-ambala"){
    //     location = "baldev nagar, ambala"
    // }
    // if(location === "indirapuram-ghaziabad"){
    //     location = "Indirapuram, Ghaziabad"
    // }
    // if(location === "azadpur-delhi"){
    //     location = "Azadpur, Delhi"
    // }
    // if(location === "sector-159-noida"){
    //     location = "sector 159, noida"
    // }
    // if(location === "greater noida west"){
    //     location = "greater noida west"
    // }
    // if(location === "nehru-nagar-hyderabad"){
    //     location = "nehru nagar, hyderabad"
    // }
    // if(location === "sector-20-rohini"){
    //     location = "sector-20, rohini"
    // }
    // if(location === "sector-18-noida"){
    //     location = "sector 18, noida"
    // }
    // if(location === "dehradun-uttarakhand"){
    //     location = "dehradun, uttarakhand"
    // }
    // if(location === "laxmi-nagar-delhi"){
    //     location = "laxmi nagar, delhi"
    // }
    // if(location === "sector-13-gurgaon"){
    //     location = "sector 13, gurgaon"
    // }
    // if(location === "sector-112-noida"){
    //     location = "sector-112, noida"
    // }
    // if(location === "chattarpur-delhi"){
    //     location = "chattarpur, delhi"
    // }
    // if(location === "sgm-nagar-faridabad"){
    //     location = "sgm nagar, faridabad"
    // }
    // if(location === "mehrauli-gurgaon"){
    //     location = "mehrauli, gurgaon"
    // }
    // if(location === "sector-16-panchkula"){
    //     location = "sector-16, panchkula"
    // }
    // if(location === "moradabad"){
    //     location = "moradabad"
    // }
    // if(location === "sector-17-gurugram"){
    //     location = "sector-17, gurugram"
    // }
    // if(location === "sector-38a-chandigarh"){
    //     location = "sector-38A, chandigarh"
    // }
    // if(location === "bhogal-delhi"){
    //     location = "bhogal, delhi"
    // }
    // if(location === "crossing-republic-ghaziabad"){
    //     location = "crossing republic, ghaziabad"
    // }
    // if(location === "sector-9a-gurgaon"){
    //     location = "sector-9A, gurgaon"
    // }
    // if(location === "lodhi-colony"){
    //     location = "lodhi colony"
    // }
    // if(location === "burari-delhi"){
    //     location = "burari, delhi"
    // }
    // if(location === "vasundhara-delhi"){
    //     location = "vasundhara, delhi"
    // }
    // if(location === "andheri-west-mumbai"){
    //     location = "andheri west, mumbai"
    // }
    // if(location === "madhapur-hyderabad"){
    //     location = "madhapur, hyderabad"
    // }
    // if(location === "alambagh-lucknow"){
    //     location = "alambagh, lucknow"
    // }
    // if(location === "caranzalem-goa"){
    //     location = "caranzalem, goa"
    // }
    // if(location === "zirakpur-punjab"){
    //     location = "zirakpur, punjab"
    // }
    // if(location === "bhommanahalli-hongasandra-bengalore"){
    //     location = "bhommanahalli hongasandra, bengalore"
    // }
    // if(location === "aditya-world-city-ghaziabad"){
    //     location = "aditya world city, ghaziabad"
    // }
    // if(location === "sector-23c-chandigarh"){
    //     location = "sector-23c, chandigarh"
    // }
    
    // package -----------------------------------------------------------
    
    
    const [packageList, setPackageList] = useState([])

    const loadData = async () => {
  
      let res = await fetch("https://api.shop.glazma.com/all-packages", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      res = await res.json();
      setPackageList(res.data);
  
    };
  
    useEffect(() => {
      loadData();
    }, []);
  
    // price ----------------------------------------------------------------------------
    
  const [serviceCategory, setServiceCategory] = useState([]);
  const [serviceItem, setServiceItem] = useState([]);

  const loadData1 = async () => {

    let res = await fetch("https://api.shop.glazma.com/all-price-list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    res = await res.json();
    setServiceItem(res.data);

    let response = await fetch( 
      "https://api.shop.glazma.com/all-price-list-category",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    response = await response.json();
    setServiceCategory(response.data);
  };

  useEffect(() => {
    loadData1();
  }, []);

    
  return (
    <div>


<Helmet>
        <title>
          {/* Glazma - Franchise  : Men's Grooming And Waxing Service. */}
          Male beauty and waxing service at home in {location} 

        </title>
        {/* Men's Grooming and Waxing Service at Home in Noida, Delhi NCR. Get best men's grooming and waxing service by professional groomers. */}
        <meta
          name="description"
          content={`
          Book Glazma online male beauty and waxing service at home in ${location}. Get best men's grooming and waxing service by professional groomers.
          `}
         
        />
      </Helmet>
      
        <Navbar/>


        <div className="header-slider header-slider-4">
          <div className="header-slider-heading-1">
          <TypeAnimation
                sequence={[
                  "lighten the dark skin",
                  2500,
                  "pre wedding groom",
                  2500,
                  "facials",
                  2500,
                  "full body wax",
                  2500,
                  "pubic hair wax",
                  2500,
                  "brazilian wax",
                  2500,
                  "full body scrub",
                  2500,
                  "full body polishing",
                  2500,
                  "manicure & pedicure",
                  2500,
                  "body therapy at home",
                  2500,
                  "hair spa at home",
                  2500,
                ]}
                speed={50}
                repeat={Infinity}
              />
            <br />
            IN <span className="location-page-location"> {location}</span>
          </div>
          <h1 className="header-slider-heading-2">
          {/* AUNTIES A REASON FOR CHINCHAT */}
          MEN'S GROOMING SERVICE AT HOME
          </h1>
        </div>
        
        
        
        <NumberIncrementComponent/>

        {/* package start --------------------------------------------------------------- */}

        <div>

        <HomePackageComponent />


    </div>

        {/* package end --------------------------------------------------------------- */}

        {/* price start --------------------------------------------------------------- */}
        
        <div>
      
        <PricePageComponent/>

      
    </div>
        
        {/* price end --------------------------------------------------------------- */}

        <WhyGlazma/>
        <LocationComponent/>
        
        <FooterComponent/>
        
    </div>
  )
}

export default LocationPageComponent