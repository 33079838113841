import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../store/cartSlice";

const LandingPricePageComponent = () => {


  const [serviceCategory, setServiceCategory] = useState([]);
  const [serviceItem, setServiceItem] = useState([]);

  const loadData = async () => {

    let res = await fetch("https://api.shop.glazma.com/all-price-list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    res = await res.json();
    setServiceItem(res.data);

    let response = await fetch( 
      "https://api.shop.glazma.com/all-price-list-category",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    response = await response.json();
    setServiceCategory(response.data);
  };

  useEffect(() => {
    loadData();
  }, []);


  // -----------------------------------

  
  const dispatch = useDispatch();

  
  return (
    <div>
      <div className="price-page">
        <div>
          <h1 className="price-page-heading">
            GLAZMA MEN'S GROOMING SERVICES
          </h1>

          <div className="price-page-content">
            {/* -------------------- list start ------------------ */}

            {serviceCategory.filter( e => e.priceListCategoryName !== "GLAZMA MEN'S - DTAN (O3+)" && e.priceListCategoryName !== "GLAZMA MEN'S - HAIR SPA" ).map((data) => (
              <div className="price-page-content-col">
                <h2 key={data._id} className="price-page-content-col-heading">
                  {data.priceListCategoryName}
                </h2>
                <div className="price-page-content-col-list">
                  {serviceItem
                    .filter(
                      (item) =>
                        item.priceListCategoryName ===
                        data.priceListCategoryName
                    )
                    .filter(
                      e =>
                        e.name !== "HEAD MASSAGE (20 MNTS)"
                        && e.name !== "FOOT MASSAGE (20 MNTS)"

                    )
                    .map((e) => (
                      <div className="price-page-content-col-list-col">
                        <div className="price-page-content-col-list-head">
                          <div className="price-page-content-col-list-heading">
                            {e.name}
                          </div>
                          <div className="price-page-content-col-list-price">
                            <s> Rs. {e.mainPrice}</s> {e.price}/-
                          </div>
                        </div>
                        <div className="price-page-content-col-list-button">
                          <span onClick={() => dispatch(addToCart(e))}>add</span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}

            {/* --------------------------------- */}

            {/* -------------------- list end ------------------ */}
          </div>
          
          <a href="#book-now" className="landing-page-button-web-yellow landing-page-button-black ">
                  Book Call Appointment
                  </a>

                  <a href="#book-appointment" className="landing-page-button-mobile-yellow landing-page-button-black">
                  Book Call Appointment
                  </a>
          
        </div>
      </div>
    </div>
  );
};

export default LandingPricePageComponent;
