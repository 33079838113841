import React from "react";
import Navbar from "../../components/user/Navbar";
import HeaderSliderComponent from "../../components/user/HeaderSliderComponent";
// import HomeHeader2Component from "../../components/user/HomeHeader2Component";
import WhyManscapeComponent from "../../components/user/WhyManscapeComponent";
import HomePackageComponent from "../../components/user/HomePackageComponent";
import DayToDayComponent from "../../components/user/DayToDayComponent";
import FaqComponent from "../../components/user/FaqComponent";
import NumberIncrementComponent from "../../components/user/NumberIncrementComponent";
import TestimonialComponent from "../../components/user/TestimonialComponent";
import WhyGlazma from "../../components/user/WhyGlazma";
import LocationComponent from "../../components/user/LocationComponent";
import FooterComponent from "../../components/user/FooterComponent";
// import ContactButtons from "../../components/user/ContactButtons";
import NavTop2Component from "../../components/user/NavTop2Component";
// import ServicesComponent from "../../components/user/ServicesComponent";
// import KeyWords from "../../components/user/KeyWords";
// import Footer from "../components/user/Footer";

import { Helmet } from "react-helmet";
import ClientSaysComponent from "../../components/user/ClientSaysComponent";
import ContactButtons from "../../components/user/ContactButtons";

const HomePage = () => {

  // const [popup, setPopup] = useState(true)
  
  return (
    <div>
      
      <Helmet>
        <title>Glazma : Luxury Men's Grooming Services in Noida, Delhi NCR</title>
        <meta
          name="description"
          content="
          Glazma offers Men's grooming services at your doorstep in Noida, Delhi NCR. Now Call Men's grooming experts and Makeup artist at home. +91 9658-153-153
          "
        />
      </Helmet>

      <div className="home-col">

      {/* <NavTopComponent /> */}
      <NavTop2Component/>
      <div className="home-col-nav">
      <Navbar />
      </div>
      <HeaderSliderComponent /> 
      <NumberIncrementComponent />
      <ClientSaysComponent/>
      {/* <HomeHeader2Component /> */}
      <WhyManscapeComponent />
      <HomePackageComponent />
      <DayToDayComponent />
      <WhyGlazma />
      <TestimonialComponent />
      </div>
      <FaqComponent />

      {/* <ServicesComponent /> */}
      <LocationComponent />
{/* <KeyWords/> */}
      <FooterComponent/>

      {/* <Footer/> */}

{/* <ContactButtons/> */}
{/* {
        popup?
        <PopupOffer setPopup={setPopup}/>
        :""
      } */}
              <ContactButtons/>
      
      
    </div>
  )
}

export default HomePage